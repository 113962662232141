import React from "react";
import { Redirect, Switch } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
// import NotFoundRoutes from './NotFoundRoutes'
// project imports
import config from "./../config";
import { useMsal } from "@azure/msal-react";
//-----------------------|| ROUTING RENDER ||-----------------------//
//AZURE LOGIN
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
//GOOGLE LOGIN
import { GoogleOAuthProvider } from "@react-oauth/google";

// const msalInstance = new PublicClientApplication({
//   auth: {
//     clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
//     authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
//     redirectUri: "https://dashboard.nbsense.in/login",
//   },
//   cache: {
//     cacheLocation: "localStorage",
//     storeAuthStateInCookie: false,
//   },
// });

const Routes = () => {
  const { instance } = useMsal();
  // Process hash fragments of azure auth before redirecting
  if (window.location.hash && instance) {
    instance.handleRedirectPromise().catch((error) => {
      console.error("Azure login error:", error);
    });
    return null; // Show a loading state while processing the hash
  }

  return (
    <Switch>
      <Redirect exact from="/" to={config.defaultPath} />
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        {/* <MsalProvider instance={msalInstance}> */}
          <React.Fragment>
            {/* Routes for authentication pages */}
            <AuthenticationRoutes />

            {/* Route for login */}
            <LoginRoutes />

            {/* Routes for main layouts */}
            <MainRoutes />
            {/* <FMSRoutes /> */}
          </React.Fragment>
        {/* </MsalProvider> */}
      </GoogleOAuthProvider>
      ;
    </Switch>
  );
};

export default Routes;
