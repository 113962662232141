// // SubscriptionErrorPage.js
// import React from "react";
// import { useHistory } from "react-router-dom";
// import maintenanceGif from "../../assets/images/subscribe.jpg";
// import { Button, Container, Typography, Grid } from "@material-ui/core";
// import { goBackToAdminView, logOut } from "../../store/actions";
// import { useDispatch, useSelector } from "react-redux";
// import "./sub.minify.css";
// const SubscriptionErrorPage = () => {
//   const dispatcher = useDispatch();
//   const history = useHistory();
//   const account = useSelector((state) => state.account);
//   const handleGoHome = () => {
//     history.push("/");
//   };
//   const handleAdminView = () => {
//     dispatcher(goBackToAdminView());
//     history.push("/");
//   };

//   const handleLogout = () => {
//     dispatcher(logOut());
//     history.push("/");
//   };
//   return (
//     <Container
//       maxWidth="sm"
//       sx={{
//         textAlign: "center",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//         paddingTop: "0px",
//       }}
//     >
//       <section class="page_session">
//         <div class="container_session">
//           <div class="row">
//             <div class="col-sm-12 ">
//               <div class="col-sm-12 col-sm-offset-1  text-center">
//                 <div class="four_zero_four_bg">
//                   <Typography variant="h4" gutterBottom className="style1">
//                     This Service is not subscribed
//                   </Typography>
//                   <Typography variant="h4" gutterBottom className="style2">
//                     For subscription queries, please call &nbsp;
//                     <a href="tel:+91 9619419288">+91 9619419288</a> <br></br>or{" "}
//                     <br></br> email sales@nbri.in for more information.
//                   </Typography>
//                 </div>

//                 <Grid
//                   container
//                   justifyContent="center"
//                   spacing={1}
//                   className="contant_box_session"
//                 >
//                   <Grid item xs={12}>
//                     <a href="mailto:sales@nbri.in">
//                       <img
//                         src={maintenanceGif}
//                         alt="Subscribe"
//                         className="centered-img"
//                       />
//                     </a>
//                   </Grid>
//                   <Grid item xs={12} container direction="column" spacing={1}>
//                     <Grid item xs={12}>
//                       <a href="mailto:sales@nbri.in">
//                         <Button
//                           variant="contained"
//                           color="primary"
//                           style={{ borderRadius: "14px" }}
//                         >
//                           Send Mail
//                         </Button>
//                       </a>
//                     </Grid>

//                     <Grid item xs={12}>
//                       <Button
//                         variant="contained"
//                         color="primary"
//                         onClick={handleGoHome}
//                         style={{ borderRadius: "14px" }}
//                       >
//                         Go to Home Page
//                       </Button>
//                     </Grid>
//                     {account.admin !== null && (
//                       <Grid item xs={12}>
//                         <Button
//                           variant="contained"
//                           color="primary"
//                           onClick={handleAdminView}
//                           style={{ borderRadius: "14px" }}
//                         >
//                           GO TO ADMIN VIEW
//                         </Button>
//                       </Grid>
//                     )}
//                     <Grid item xs={12}>
//                       <Button
//                         variant="contained"
//                         color="error"
//                         onClick={handleLogout}
//                         style={{ borderRadius: "14px" }}
//                       >
//                         Logout
//                       </Button>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </Container>
//   );
// };

// export default SubscriptionErrorPage;

import React from "react";
import { useHistory } from "react-router-dom";
import maintenanceGif from "../../assets/images/subscribe.png";
import { Button, Container, Grid } from "@material-ui/core";
import { goBackToAdminView, logOut } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import "./sub.minify.css";  // Ensure your CSS is linked properly

const SubscriptionErrorPage = () => {
  const dispatcher = useDispatch();
  const history = useHistory();
  const account = useSelector((state) => state.account);

  const handleGoHome = () => {
    history.push("/");
  };

  const handleAdminView = () => {
    dispatcher(goBackToAdminView());
    history.push("/");
  };

  const handleLogout = () => {
    dispatcher(logOut());
    history.push("/");
  };

  return (
    <Container
      maxWidth="lg"
      style={{
        flex: 1, 
        padding: "20px", 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        textAlign: 'center',
        height: '100vh', // Make sure the section takes up full viewport height
      }}
    >
      {/* Left section with the GIF */}
      <div className="left-section">
        <img
          src={maintenanceGif}
          alt="Subscription Error"
          className="maintenance-gif"
        />
      </div>

      {/* Right section with text and buttons */}
      <section className="right-section">
        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="left">
              <span className="line"></span>
              <h2>
                <span style={{ color: "#212121" }}>
                  Hello, This Service <br />
                </span>
                is not
                <span style={{ color: "#dc143c" }}> Subscribed</span>
              </h2>
              <center>
              <p>For Subscription queries, please call +91 904 904 8044</p>
              </center>

                  <center>    
              <p>OR</p>
              </center>

              <center>
              <p>email sales@nbri.in for more information</p>
              </center>

              <a href="tel:+91 9049048044">
                <Button
                                      variant="contained"
                                      style={{ borderRadius: "14px",fontWeight:'600', width: "100%",letterSpacing: '1px',transition: 'all .2s linear',boxShadow: '0px 8px 24px 0 rgba(228, 10, 57, .2)',backgroundColor:'rgba(228, 10, 57, .8)',fontSize:'14px'}}
                >
                Contact
                </Button>
              </a>

            </div>
            <div className="right">
              {/* Button Row: All buttons in one row */}
              <Grid
                container
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                {/* Send Mail Button */}
                <Grid item xs={6}>
                  <a href="mailto:sales@nbri.in">
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ borderRadius: "14px", width: "100%" }}
                    >
                      Send Mail
                    </Button>
                  </a>
                </Grid>

                {/* Go To Home Page Button */}
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGoHome}
                    style={{ borderRadius: "14px", width: "100%" }}
                  >
                    Home Page
                  </Button>
                </Grid>

              </Grid>
<br></br>
              <Grid
                container
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >

                {/* Go To Admin View Button */}
                {account.admin !== null && (
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAdminView}
                      style={{ borderRadius: "14px", width: "100%" }}
                    >
                      Admin View
                    </Button>
                  </Grid>
                )}

                {/* Logout Button */}
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleLogout}
                    style={{ borderRadius: "14px", width: "100%" }}
                  >
                    Logout
                  </Button>
                </Grid>

              </Grid>
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
};

export default SubscriptionErrorPage;
