import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Icon from "../../../../assets/images/User.gif";
import renewalImage from "../../../../assets/images/renewal.png";
import smsImage from "../../../../assets/images/sms.png";
import mailImage from "../../../../assets/images/mail.png";
import callImage from "../../../../assets/images/call.png";
// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Avatar,
  CardContent,
  Chip,
  ClickAwayListener,
  List,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography,
  Snackbar,
  Alert,
  Grid,
  Card,
  Box,
  Modal,
  Button,
  Stack,
} from "@material-ui/core";
import ListItemButton from "@material-ui/core/ListItemButton";
import configData from "../../../../config";
import axios from "axios";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
// import axios from 'axios';

// project imports
import MainCard from "../../../../ui-component/cards/MainCard";
import Transitions from "../../../../ui-component/extended/Transitions";

import { logOut } from "../../../../store/actions";

// assets
import { IconLogout, IconUser, IconSettings } from "@tabler/icons";
import { BrowserView, MobileView } from "react-device-detect";
// style const
const useStyles = makeStyles((theme) => ({
  navContainer: {
    width: "100%",
    maxWidth: "350px",
    minWidth: "200px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  headerAvatar: {
    cursor: "pointer",
    ...theme.typography.mediumAvatar,
    margin: "8px 0 8px 2px !important",
  },
  profileChip: {
    // flexGrow: 1,
    height: "40px",
    alignItems: "center",
    borderRadius: "25px",
    transition: "all .2s ease-in-out",
    borderColor: theme.palette.primary.light,
    backgroundColor: theme.palette.secondary.main,
    '&[aria-controls="menu-list-grow"], &:hover': {
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.main + "!important",
      color: theme.palette.primary.light,
      "& svg": {
        stroke: theme.palette.primary.light,
      },
    },
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  profileLabel: {
    lineHeight: 1.2,
    padding: "10px",
  },
  listItem: {
    marginTop: "5px",
  },
  cardContent: {
    padding: "16px !important",
  },
  card: {
    backgroundColor: theme.palette.primary.light,
    marginBottom: "16px",
    marginTop: "16px",
  },
  searchControl: {
    width: "100%",
    paddingRight: "8px",
    paddingLeft: "16px",
    marginBottom: "16px",
    marginTop: "16px",
  },
  startAdornment: {
    fontSize: "1rem",
    color: theme.palette.grey[500],
  },
  flex: {
    display: "flex",
  },
  name: {
    marginLeft: "2px",
    fontWeight: 400,
  },
  ScrollHeight: {
    height: "100%",
    maxHeight: "calc(100vh - 250px)",
    overflowX: "hidden",
  },
  badgeWarning: {
    backgroundColor: theme.palette.warning.dark,
    color: "#fff",
  },
  customTypography: {
    [theme.breakpoints.down("lg")]: {
      fontSize: "9px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "9px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "9px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "9px",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "8px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "8px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "8px",
    },
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: 24,
    padding: theme.spacing(3),
    outline: "none",
  },
}));

//-----------------------|| PROFILE MENU ||-----------------------//

const ProfileSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const account = useSelector((state) => state.account);
  const username = account.user?.name || "";
  const isLongUsername = username.length > 10;
  const history = useHistory();
  const dispatcher = useDispatch();
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  // Snackbar states
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarColor, setSnackbarColor] = React.useState("success");
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [credits, setCredits] = React.useState(100);
  const [mail, setMail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [renewal, setRenewal] = React.useState("");

  const cardSX = {
    content: '""',
    position: "absolute",
    width: 200,
    height: 200,
    borderColor: "error.main",
  };

  React.useEffect(() => {
    const fetchAccessData = async () => {
      try {
        const token = account.token;
        const response = await axios.get(
          configData.API_SERVER + "user/profile",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCredits(response.data.credit);
        setMail(response.data.client_email);
        setPhone(response.data.contact_no);
        setRenewal(response.data.renewal_date);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAccessData();
  }, [account.token]);

  const handleLogout = () => {
    dispatcher(logOut());
  };
  const handlePassword = () => {
    dispatcher(logOut());
    history.push("/forgot"); // Redirect to /forgot after logout
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  React.useEffect(() => {
    if (credits <= 0) {
      setSnackbarMessage(`Please Contact Sales. Balance is ${credits}.`);
      setSnackbarColor("error");
      setSnackbarOpen(true);
    } else if (credits <= 20) {
      setSnackbarMessage(
        `Your sms credit limit will expire soon. Balance is ${credits}.`
      );
      setSnackbarColor("error");
      setSnackbarOpen(true);
    } else if (credits <= 60) {
      setSnackbarMessage(
        `Your sms credit limit is low. Balance is ${credits}.`
      );
      setSnackbarColor("warning");
      setSnackbarOpen(true);
    }
  }, [credits]);

  return (
    <React.Fragment>
      {/* Snackbar for credit messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom", // Position vertically at the bottom
          horizontal: "right", // Position horizontally to the right
        }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarColor}
          sx={{
            width: "100%",
            fontSize: "16px", // Set font size to 16px
            fontWeight: 600, // Set font weight to 600
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <BrowserView>
        <Tooltip title={account.user?.name || ""} placement="bottom">
          <Chip
            classes={{ label: classes.profileLabel }}
            className={classes.profileChip}
            icon={
              <Avatar
                src={
                  account.user ? (
                    <Typography
                      variant="h4"
                      // style={
                      //   isLongUsername
                      //     ? { fontSize: "8px" }
                      //     : { fontSize: "14px" }
                      // }
                    >
                      {account.user.name ? account.user.name[0] : ""}
                    </Typography>
                  ) : null
                }
                className={classes.headerAvatar}
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                color="inherit"
              />
            }
            label={
              <Typography
                variant="h4"
                style={{
                  fontSize: "16px",
                  padding: "0px 10px 0px 0px",
                  color: "#fff",
                }}
              >
                {account.user?.name || ""}
              </Typography>
            }
            variant="outlined"
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="primary"
          />
        </Tooltip>
      </BrowserView>
      <MobileView>
        <Tooltip title={account.user?.name || ""} placement="bottom">
          <Chip
            classes={{ label: classes.profileLabel }}
            className={classes.profileChip}
            icon={
              <Avatar
                src={
                  account.user ? (
                    <Typography variant="h4">
                      {account.user.name ? account.user.name[0] : ""}
                    </Typography>
                  ) : null
                }
                className={classes.headerAvatar}
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                color="inherit"
              />
            }
            label={
              <Typography
                variant="h4"
                style={{
                  fontSize: "16px",
                  padding: "0px 10px 0px 0px",
                  color: "#fff",
                }}
              >
                {account.user && account.user.name
                  ? account.user.name.slice(0, 1).toUpperCase()
                  : ""}
              </Typography>
            }
            variant="outlined"
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="primary"
          />
        </Tooltip>
      </MobileView>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14], //For Logout card width float
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <CardContent className={classes.cardContent}>
                    <PerfectScrollbar className={classes.ScrollHeight}>
                      <List component="nav" className={classes.navContainer}>
                        {/* <ListItemButton
                          className={classes.listItem}
                          sx={{
                            borderRadius: customization.borderRadius + "px",
                          }}
                        > */}
                        {/* <ListItemIcon>
                            <IconUser
                              stroke={1.5}
                              size="1.5rem"
                              style={{ color: "#5e35b1" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">
                                {account.user?.name || ""}
                              </Typography>
                            }
                          /> */}

                        <Card
                          sx={{
                            bgcolor: "primary.light",
                            my: 2,
                            overflow: "hidden",
                            position: "relative",
                            "&:after": {
                              border: "19px solid ",
                              borderRadius: "50%",
                              top: "65px",
                              right: "-150px",
                              ...cardSX,
                            },
                            "&:before": {
                              border: "3px solid ",
                              borderRadius: "50%",
                              top: "145px",
                              right: "-70px",
                              ...cardSX,
                            },
                          }}
                        >
                          <CardContent>
                            <Grid container direction="column" spacing={2}>
                              <Grid item>
                                <Typography variant="h4">
                                  <IconUser
                                    stroke={1.5}
                                    size="1.5rem"
                                    style={{ color: "#5e35b1" }}
                                  />{" "}
                                  &nbsp;
                                  {account.user?.name || ""}
                                </Typography>
                              </Grid>
                              {/* <Grid item>
            <Typography variant="subtitle2" color={'grey.900'} sx={{ opacity: 0.6 }}>
              70% discount for 1 years <br />
              subscriptions.
            </Typography>
          </Grid> */}
                              <Grid item>
                                <Stack direction="row">
                                  <ListItemButton onClick={handleModalOpen}>
                                    <Button variant="contained" color="primary">
                                      View Profile
                                    </Button>
                                  </ListItemButton>
                                </Stack>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                        {/* </ListItemButton> */}

                        {/* <ListItemButton
                          className={classes.listItem}
                          sx={{
                            borderRadius: customization.borderRadius + "px",
                          }}
                        >
                          <ListItemIcon>
                            <IconUser
                              stroke={1.5}
                              size="1.5rem"
                              style={{ color: "#5e35b1" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">
                                {account.user?.name || ""}
                              </Typography>
                            }
                          />
                        </ListItemButton> */}
                        <ListItemButton
                          className={classes.listItem}
                          sx={{
                            borderRadius: customization.borderRadius + "px",
                          }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout
                              stroke={1.5}
                              size="1.5rem"
                              style={{ color: "#ef4350" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2" color="error">
                                Logout
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </PerfectScrollbar>
                  </CardContent>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>

      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box className={classes.modalStyle}>
          {/* Modal Header */}
          <Typography
            sx={{
              color: "#331d5b",
              fontWeight: "bold",
              mb: 2,
              fontSize: "1.5rem",
            }}
          >
            Profile Details
          </Typography>
          <hr style={{ border: "1px solid #331d5b", marginBottom: "20px" }} />

          {/* Profile Details */}
          <Grid
            container
            spacing={1}
            sx={{ overflow: "hidden", display: "flex", alignItems: "center" }}
          >
            {/* {Image} */}
            <Grid item xs={2}>
              <img
                src={Icon}
                alt="icon"
                style={{ width: "120px", height: "100px" }}
              />
            </Grid>
            <Grid item xs={10} sx={{ textAlign: "right" }}>
              <Typography
                sx={{ fontSize: "1.8rem", fontWeight: "600", color: "#216cca" }}
              >
                {account.user?.name || ""}
              </Typography>
            </Grid>

            {/* Renewal Date */}
            <Grid item xs={2}>
              <img
                src={renewalImage}
                alt="renewal"
                style={{ width: "40px", height: "45px" }}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ color: "#212121", fontWeight: "bold", fontSize: "1rem" }}
              >
                Renewal Date:
              </Typography>
            </Grid>
            <Grid item xs={7} sx={{ textAlign: "right" }}>
              <Typography
                sx={{ fontSize: "1.2rem", fontWeight: "600", color: "#216cca" }}
              >
                {renewal}
              </Typography>
            </Grid>

            {/* Renewal Date */}
            <Grid item xs={2}>
              <img
                src={callImage}
                alt="call"
                style={{ width: "50px", height: "45px" }}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ color: "#212121", fontWeight: "bold", fontSize: "1rem" }}
              >
                Phone No:
              </Typography>
            </Grid>
            <Grid item xs={7} sx={{ textAlign: "right" }}>
              <Typography
                sx={{ fontSize: "1.2rem", fontWeight: "600", color: "#216cca" }}
              >
                {phone}
              </Typography>
            </Grid>

            {/* Email ID */}
            <Grid item xs={2}>
              <img
                src={mailImage}
                alt="mail"
                style={{ width: "45px", height: "40px" }}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ color: "#331d5b", fontWeight: "bold", fontSize: "1rem" }}
              >
                Email ID:
              </Typography>
            </Grid>
            {/* <Grid item xs={7} sx={{ textAlign: "right" }}>
              <Typography
                sx={{ fontSize: "1.2rem", fontWeight: "600", color: "#216cca",overflow:'visible' }}
              >
                {mail}
              </Typography>
            </Grid> */}
            <Grid item xs={7} sx={{ textAlign: "right" }}>
              <Typography
                sx={{
                  fontSize: "1.15rem",
                  fontWeight: "600",
                  color: "#216cca",
                  overflow: "auto",
                  maxHeight: "60px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {mail}
              </Typography>
            </Grid>

            {/* SMS Credit */}
            <Grid item xs={2}>
              <img
                src={smsImage}
                alt="sms"
                style={{ width: "45px", height: "50px" }}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ color: "#331d5b", fontWeight: "bold", fontSize: "1rem" }}
              >
                SMS Credit:
              </Typography>
            </Grid>
            <Grid item xs={7} sx={{ textAlign: "right" }}>
              <Typography
                sx={{ fontSize: "1.5rem", fontWeight: "600", color: "#216cca" }}
              >
                {credits}
              </Typography>
            </Grid>
          </Grid>

          {/* Buttons */}
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 3 }}>
            <Button variant="contained" onClick={handlePassword}>
              Change Password
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleModalClose}
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ProfileSection;
