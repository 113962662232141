import React, { useState, useEffect  } from "react";
import PropTypes from "prop-types";
import { tabsClasses } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Avatar, Tab, Box, Tabs, ButtonBase,Menu, MenuItem} from "@material-ui/core";
import "@fortawesome/fontawesome-free/js/all.js";
// project imports
import LogoSection from "../LogoSection";
import ProfileSection from "./ProfileSection";
// import NotificationSection from "../Header/NotificationSection/index";
import TempImage from "./../../../assets/images/icons/Temp.png";
import UPSImage from "./../../../assets/images/icons/Ups.png";
import DGImage from "./../../../assets/images/icons/Dg.png";
import WaterImage from "./../../../assets/images/icons/water.png";
import VoltImage from "./../../../assets/images/icons/ems.png";
import FireImage from "./../../../assets/images/icons/fire system.png";
import HvacImage from "./../../../assets/images/icons/hvac.png";
import VibImage from "./../../../assets/images/icons/vib.png";
import ShieldImage from "./../../../assets/images/icons/NBSafe.png";
import TransImage from "./../../../assets/images/icons/trans.png";

// assets
import { IconMenu2 } from "@tabler/icons";
import { useHistory } from "react-router-dom";
import dashboardType from "../../../store/dashboard_type";

// style constant
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 0,
  },
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: "all .2s ease-in-out",
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    "&:hover": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.light,
    },
  },
  boxContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      marginRight: "5%",
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: "5%",
    },
  },
  subHeading: {
    width: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "40px",
    boxShadow:
      "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
  },
  tagGroup: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
}));

//-----------------------|| MAIN NAVBAR / HEADER ||-----------------------//

const Header = ({ handleLeftDrawerToggle, currentIndex = 0 }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(currentIndex);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRedirect = (path) => {
    history.push(path);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // const handleSubmenuToggle = () => {
  //   setSubmenuOpen(!submenuOpen); // Toggle submenu open/close on click
  // };

  const handleSubmenuToggle = (event) => {
    setAnchorEl(event.currentTarget); // Set anchorEl when submenu is toggled
    setSubmenuOpen(!submenuOpen); // Toggle submenu open/close on click
  };

    // Adjust for mobile screen
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 600); // Check for mobile screen
      };
  
      handleResize();
      window.addEventListener("resize", handleResize);
  
      return () => window.removeEventListener("resize", handleResize);
    }, []);

  var tabsData = [
    {
      label: "Energy",
      image: VoltImage,
      path: "/ems/home",
      width: "25px",
      height: "25px",
    },
    // {
    //   label: "Water",
    //   image: WaterImage,
    //   path: "/wms/home",
    //   width: "20px",
    //   height: "20px",
    // },
    {
      label: "Water",
      image: WaterImage,
      path: "/wms/home",
      width: "20px",
      height: "20px",
      submenu: [
        { label: "Flow", path: "/wms/home" },
        { label: "Quality", path: "/wms/quality" },
      ],
    },
    {
      label: "DG",
      image: DGImage,
      path: "/dg/home",
      width: "20px",
      height: "20px",
    },
    {
      label: "UPS",
      image: UPSImage,
      path: "/bms/home",
      width: "20px",
      height: "20px",
    },
    {
      label: "Temp/Humidity",
      image: TempImage,
      path: "/temp/home",
      width: "20px",
      height: "20px",
    },
    {
      label: "Hvac",
      image: HvacImage,
      path: "/hvc/home",
      width: "20px",
      height: "20px",
    },
    {
      label: "Vibration",
      image: VibImage,
      path: "/vib/home",
      width: "20px",
      height: "20px",
    },
    {
      label: "Fire System",
      image: FireImage,
      path: "/fms/home",
      width: "20px",
      height: "20px",
    },
    {
      label: "NBSafe",
      image: ShieldImage,
      path: "/nbs/home",
      width: "20px",
      height: "20px",
    },    
    {
      label: "Transformer",
      image: TransImage,
      path: "/trans/home",
      width: "20px",
      height: "20px",
    },
  ];

  if (
    currentIndex === dashboardType.admin ||
    currentIndex === dashboardType.superAdmin ||
    currentIndex === dashboardType.supportAdmin
  ) {
    tabsData = [];
  }

  return (
    <React.Fragment>
      {/* logo & toggler button */}
      <div className={classes.boxContainer}>
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>

        <ButtonBase
          sx={{
            display: { xs: "block", md: "none" },
            borderRadius: "3px",
            overflow: "hidden",
          }}
        >
          <Avatar
            variant="rounded"
            className={classes.headerAvatar}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={3.5} size="1rem" />
          </Avatar>
        </ButtonBase>
      </div>
      <Box
        sx={{
          flexGrow: 1,
          width: {
            xs: "60%",
            sm: "60%",
            md: "60%",
            display: "flex",
            justifyContent: "center",
            alignItems:'center'
          },
          bgcolor: "background.paper",
        }}
      >
        <Tabs
          TabIndicatorProps={{ sx: {height: "4px", borderRadius: "1px" } }}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="scrollable auto tabs example"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
        >
          {tabsData.map((tab, index) => (
        //     <Tab
        //       key={index}
        //       onClick={() => handleRedirect(tab.path)}
        //       icon={
        //         <div style={{ display: "flex", alignItems: "center" }}>
        //           <img
        //             src={tab.image}
        //             alt={tab.label}
        //             style={{
        //               marginRight: "8px", // Adjust the margin as needed
        //               width: tab.width,
        //               height: tab.height,
        //             }}
        //           />
        //           <span style={{ fontWeight: "900" }}>{tab.label}</span>
        //         </div>
        //       }
        //     />
        //   ))}
        // </Tabs>
        <Tab
        key={index}
        // onClick={!tab.submenu ? () => handleRedirect(tab.path) : undefined}
        onClick={
          tab.label === "Water" ? handleSubmenuToggle : () => handleRedirect(tab.path)
        }
        icon={
          <div style={{ display: "flex", alignItems: "center" }} onMouseEnter={tab.submenu ? handleMenuOpen : undefined}>
            <img src={tab.image} alt={tab.label} style={{ marginRight: "8px", width: tab.width, height: tab.height }} />
            <span style={{ fontWeight: "900" }}>{tab.label}</span>
          </div>
        }
      />
    ))}
  </Tabs>
      </Box>

      {/* {submenuOpen && (
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{ onMouseLeave: handleMenuClose }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: -20, horizontal: "center" }}
        PaperProps={{
          style: { display: "flex", flexDirection: "row", padding: "10px", borderRadius:'10px',gap: "10px", boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.2)","&:hover": {boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.2)"},},
        }}
      >
        {tabsData.find((tab) => tab.label === "Water")?.submenu?.map((submenuItem, subIndex) => (
          <MenuItem key={subIndex} onClick={() => handleRedirect(submenuItem.path)} sx={{ fontSize: "15px",fontWeight:'600' }}>
            {submenuItem.label}
          </MenuItem>
        ))}
      </Menu>
 )} */}
       {submenuOpen && (
        <Menu
          anchorEl={anchorEl}
          open={submenuOpen}
          onClose={() => setSubmenuOpen(false)} // Close submenu on clicking outside
          MenuListProps={{ onMouseLeave: () => setSubmenuOpen(false) }}
          anchorOrigin={{ vertical:"bottom", horizontal: "center" }}
          transformOrigin={{ vertical: -20, horizontal: "center" }}
          // anchorOrigin={{
          //   vertical: isMobile ? 40 : "bottom", // Adjust based on screen size
          //   horizontal: "center",
          // }}
          // transformOrigin={{
          //   vertical: isMobile ? 40 : "bottom", // Adjust based on screen size
          //   horizontal: "center",
          // }}
          PaperProps={{
            style: { display: "flex", flexDirection: "row", padding: "10px", borderRadius: "10px", gap: "10px", boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.2)" },
          }}
        >
          {tabsData.find((tab) => tab.label === "Water")?.submenu?.map((submenuItem, subIndex) => (
            <MenuItem key={subIndex} onClick={() => handleRedirect(submenuItem.path)} sx={{ fontSize: "15px", fontWeight: "600" }}>
              {submenuItem.label}
            </MenuItem>
          ))}
        </Menu>
      )}

      <div className={classes.grow} />
      {/* <NotificationSection/> */}
      <ProfileSection />
    </React.Fragment>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
